import React from "react"

import Section from "../../Elements/Section"
import FormRadio from "../../Elements/Form/FormRadio"
import EditDetailsButton from "../../Summary/EditDetailsButton"
import AdherenceLikertScale from "../HealthSurvey/AdherenceLikertScale"

const BarriersToAdherenceSummary = ({ values }) => (
  <Section
    title="Barriers to Adherence"
    addOns={{
      right: (
        <EditDetailsButton route="/epharmacy/health-survey#barriers-to-adherence" />
      ),
    }}
  >
    <FormRadio
      name="howWellMedicineWorks"
      title="How well does this medicine work for you?"
      value={values?.howWellMedicineWorks}
      options={[values?.howWellMedicineWorks]}
      isRequired
      disabled
    />
    <AdherenceLikertScale values={values} disabled />
  </Section>
)

export default BarriersToAdherenceSummary
