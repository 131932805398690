import React from "react"

import Section from "../../Elements/Section"
import FormSelect from "../../Elements/Form/FormSelect"
import EditDetailsButton from "../../Summary/EditDetailsButton"

import formFields from "../utils/healthSurveyDynamicFields.json"
import { generateFormField } from "../../Elements/Form/services/form"

const PrescriptionInformationSummary = ({ values }) => (
  <Section
    title="Prescription Information"
    addOns={{
      right: (
        <EditDetailsButton route="/epharmacy/health-survey#prescription-information" />
      ),
    }}
  >
    <FormSelect
      placeholder="Select medicine"
      label="What medicine was prescribed to you?"
      helper="If you have multiple medicines, please just pick one that you most frequently take."
      name="medicinePrescribed"
      isDisabled
      value={values?.medicinePrescribed}
      isRequired
    />
    <FormSelect
      placeholder="Select or type answer"
      label="Why was the medicine prescribed to you?"
      name="whyMedicineWasPrescribed"
      value={
        values?.whyMedicineWasPrescribed?.value === "Other"
          ? { label: values?.otherWhyMedicineWasPrescribed }
          : values?.whyMedicineWasPrescribed
      }
      isRequired
      isDisabled
    />
    {formFields.map((formField) => {
      if (values[formField?.name] && values[formField?.name]?.length > 0) {
        let options = [values[formField?.name]]
        if (typeof values[formField?.name] === "object")
          options = [...values[formField?.name]]
        return generateFormField({
          formField: {
            ...formField,
            followUpQuestions: [],
            disabled: true,
            options: options,
          },
          values,
          formFields,
        })
      }
    })}
  </Section>
)

export default PrescriptionInformationSummary
