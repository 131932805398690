import React, { useState, useEffect, useContext } from "react"
import FormCheckbox from "../../Elements/Form/FormCheckbox"

import { AppContext } from "../../../context/AppContext"
import barriersToAdherence from "./utils/barriersToAdherence.json"
import { shuffle } from "lodash"

const AdherenceLikertScale = ({ values }) => {
  const { state, dispatch } = useContext(AppContext)
  const [
    shuffledBarriersToAdherence,
    setShuffledBarriersToAdherence,
  ] = useState([])

  useEffect(() => {
    if (state.adherenceLikert)
      setShuffledBarriersToAdherence(state.adherenceLikert)
    else {
      const data = shuffle(barriersToAdherence)
      setShuffledBarriersToAdherence(data)
      dispatch({ type: "SAVE_SHUFFLED_BARRIERS_ADHERENCE", payload: data })
    }
  }, [])

  return (
    <div className="mt-2">
      <FormCheckbox
        values={values?.adherenceLikert}
        options={shuffledBarriersToAdherence}
        name="adherenceLikert"
        title="When you fail to take the right medicine at the right time, what are the
        usual reasons?"
        helper="Check all that apply."
        hideOptional
      />
    </div>
  )
}
export default AdherenceLikertScale
