import { generateDeliveryFee } from "../../../Summary/services/deliveryFee"

import { accumulatePrice } from "../../services/computations"
import { parseMedicines } from "../../services/parseMedicines"

const printDateValue = ({ date }) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${
    date.year?.value || date.year
  }`
}

export function orderTicketBody(request) {
  let {
    firstName,
    lastName,
    emailAddress,
    mobileNumber,
    medicines,
    deliveryAddress,
    paymentOption,
    changeFor,
    creditCardBank,
    creditCardType,
    dispensingChannel,
    orderNotes,
    courierOption,
    preferredDeliveryDate,
    preferredDeliveryTime,
    doctorName,
    hospitalName,
    otherHospital,
    authorizedPerson,
    patientCode,
    isSameDay,
    contactPerson,
    bankName,
    products,
    agreeContactPerson,
    otherDispensingChannel,
    feedback,
    rating,
  } = request

  const medicineBody = parseMedicines({ medicines, products })

  let deliveryFee =
    generateDeliveryFee({
      medicines,
      dispensingChannel: courierOption,
      province: deliveryAddress?.province?.value,
      city: deliveryAddress?.city?.value,
      cartSize: accumulatePrice(medicines),
    }) || 0

  let contactPersonName =
    contactPerson.length === 0
      ? authorizedPerson.fullName
      : `${firstName} ${lastName}`

  let contactPersonNumber =
    contactPerson.length === 0 ? authorizedPerson.contactNumber : mobileNumber

  return `First name: ${firstName}
  Last name: ${lastName}
  Mobile number: ${mobileNumber}
  Email: ${emailAddress}
  CarePlus Patient Code: ${patientCode || "N/A"}
  Prescribing Doctor: ${doctorName} 
  Hospital Name: ${
    hospitalName?.value === "Other" ? otherHospital : hospitalName?.value
  }
  Street address: ${deliveryAddress.streetAddress} 
  City/Municipality: ${deliveryAddress.city.value}
  Province: ${deliveryAddress.province.value}  
  Barangay: ${deliveryAddress?.barangay?.value}  
  Address type: ${deliveryAddress.addressType} 
  ZIP Code: ${deliveryAddress?.zipCode?.value || "N/A"} 
  Preferred Delivery Date: ${
    isSameDay === "Yes"
      ? "Same day delivery"
      : printDateValue({ date: preferredDeliveryDate })
  } 
  Preferred Delivery Time: ${
    isSameDay === "Yes" ? "Same day delivery" : preferredDeliveryTime
  } 
  Authorized Person to receive: ${contactPersonName} 
  Contact number of authorized person: ${contactPersonNumber}
  ✅ ${agreeContactPerson?.join()} 
  Rating: ${rating?.value || "N/A"}
  Feedback: ${feedback || "N/A"}
  Delivery notes: ${deliveryAddress?.notes}
  
  Medicines ordered:
  ${medicineBody}

  If your preferred dispensing channel encounters challenges delivering your order: ${
    dispensingChannel || ""
  }
  Other dispensing channel: ${
    dispensingChannel === "(Recommended) Switch to another dispensing channel"
      ? otherDispensingChannel
      : "N/A"
  }
  Order notes: ${orderNotes || "N/A"}
  Preferred courier: ${courierOption} 
  Payment method: ${
    paymentOption === "Bank Transfer/Deposit"
      ? `Bank Transfer/Deposit - ${bankName}`
      : paymentOption || "N/A"
  }
  Bank Name: ${bankName || ""}
  Change For: ${paymentOption === "Cash on Delivery" ? changeFor : "N/A"}
  Delivery Fee: ${deliveryFee || 0}
  Credit Card Bank: ${
    paymentOption === "Credit Card Upon Delivery" && creditCardBank
      ? creditCardBank
      : "N/A"
  }
  Credit Card Type: ${
    paymentOption === "Credit Card Upon Delivery" && creditCardType
      ? creditCardType
      : "N/A"
  }`
}
