import React from "react"

import Section from "../../Elements/Section"
import FormRadio from "../../Elements/Form/FormRadio"
import EditDetailsButton from "../../Summary/EditDetailsButton"

const AdherenceInformationSummary = ({ values }) => (
  <Section
    title="Adherence Information"
    addOns={{
      right: (
        <EditDetailsButton route="/epharmacy/health-survey#adherence-information" />
      ),
    }}
  >
    <p className="has-text-grey is-size-6">In the past week,</p>
    <FormRadio
      name="hasTakenThisDrug"
      title="Did you take this drug?"
      value={values?.hasTakenThisDrug}
      options={[values?.hasTakenThisDrug]}
      isRequired
      disabled
    />
  </Section>
)

export default AdherenceInformationSummary
