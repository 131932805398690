import React, { Fragment, useContext, useState } from "react"
import { Formik, Form } from "formik"
import { navigate } from "gatsby"

import ActionButtons from "../Elements/ActionButtons"
import ConsentAndAuthorization from "../Summary/ConsentAndAuthorization"
import BarriersToAdherenceSummary from "./HealthSurveySummary/BarriersToAdherenceSummary"
import AdherenceInformationSummary from "./HealthSurveySummary/AdherenceInformationSummary"
import PrescriptionInformationSummary from "./HealthSurveySummary/PrescriptionInformationSummary"

import { AppContext } from "../../context/AppContext"
import { sendRequest } from "../../services/order"
import { shouldBeDisabled } from "./services/summary"
import { healthSurveyValidationSchema } from "./utils/formData"
import { orderTicketBody } from "./utils/templates/epharmacyOrderZendeskTemplate"
import { orderConfirmation } from "./utils/templates/epharmacyOrderEmailTemplate"
import termsAndConditionsData from "../Enrollment/utils/doctorConsentAndAuthorization.json"

const HealthSurveySummary = ({ backRoute }) => {
  const { state, dispatch } = useContext(AppContext)
  const [loading, setLoading] = useState(false)
  const { epharmacy, scpwdId, documents } = state
  const { medicines, healthSurvey } = epharmacy
  let uploadedDocuments = [...documents]
  if (scpwdId.front.path) uploadedDocuments.push(scpwdId.front)
  if (scpwdId.back.path) uploadedDocuments.push(scpwdId.back)

  const handleOnSubmit = async () => {
    setLoading(true)
    await sendRequest(
      "epharmacy",
      state,
      uploadedDocuments,
      orderConfirmation,
      orderTicketBody,
      async () => {
        await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })
        await dispatch({ type: "RESET_DETAILS" })
        navigate("/epharmacy/completed")
      }
    )
  }

  return (
    <Fragment>
      <Formik
        initialValues={{ ...healthSurvey }}
        onSubmit={handleOnSubmit}
        validationSchema={healthSurveyValidationSchema}
      >
        {({ values }) => (
          <Form>
            <PrescriptionInformationSummary values={values} />
            <AdherenceInformationSummary values={values} />
            <BarriersToAdherenceSummary values={values} />
          </Form>
        )}
      </Formik>
      <ConsentAndAuthorization
        termsAndConditionsData={termsAndConditionsData}
        handleOnSubmit={handleOnSubmit}
        flow="epharmacy"
      >
        <div className="notification is-warning has-text-centered is-size-6 mt-3">
          Order is not final until verified by our pharmacist via SMS.
        </div>
        <ActionButtons
          submit={{
            label: "Submit",
            loading: loading,
            disabled: shouldBeDisabled({ formData: epharmacy, medicines }),
          }}
          back={{ label: "Back", link: backRoute }}
        />
      </ConsentAndAuthorization>
    </Fragment>
  )
}

export default HealthSurveySummary
